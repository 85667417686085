import { render, staticRenderFns } from "./TranslatorSelect.vue?vue&type=template&id=97278572&scoped=true&"
import script from "./TranslatorSelect.vue?vue&type=script&lang=js&"
export * from "./TranslatorSelect.vue?vue&type=script&lang=js&"
import style1 from "./TranslatorSelect.vue?vue&type=style&index=1&id=97278572&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97278572",
  null
  
)

export default component.exports