var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isValidSpecifics)?_c('div',{class:_vm.genBem(_vm.blockCls)},[_c('h3',[_vm._v("Customer Preferences")]),_vm._v(" "),_c('div',{class:[
            _vm.genBem(_vm.blockCls, 'question-wrapper'),
            _vm.genBem(_vm.blockCls, 'translator')
         ]},[_c('p',{class:_vm.getQuestionCls('translator')},[_vm._v("\n            "+_vm._s(_vm.$t('BOOKING_SPECIFICS.TRANSLATOR'))+"\n        ")]),_vm._v(" "),_c('p',{class:_vm.getAnswerCls('translator')},[_c('span',{class:_vm.getIconCls('translator')}),_vm._v("\n            "+_vm._s(_vm.getAnswer('translator'))+"\n\n            "),(_vm.getValue('translator'))?_c('el-button',{class:_vm.getButtonCls('translator'),attrs:{"type":"primary","size":"mini","disabled":_vm.isAdjusted.translator},on:{"click":function($event){return _vm.handleClickAdjust('translator')}}},[_vm._v("\n                "+_vm._s(_vm.isAdjusted.translator ? 'Adjusted' : 'Adjust')+"\n            ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{class:[
            _vm.genBem(_vm.blockCls, 'question-wrapper'),
            _vm.genBem(_vm.blockCls, 'gender')
         ]},[_c('p',{class:_vm.getQuestionCls('gender')},[_vm._v("\n            "+_vm._s(_vm.$t('BOOKING_SPECIFICS.GENDER'))+"\n        ")]),_vm._v(" "),_c('p',{class:_vm.getAnswerCls('gender')},[_c('span',{class:_vm.getIconCls('gender')}),_vm._v("\n            "+_vm._s(_vm.getAnswer('gender'))+"\n\n            "),(_vm.getValue('gender'))?_c('el-button',{class:_vm.getButtonCls('gender'),attrs:{"type":"primary","size":"mini","disabled":_vm.isAdjusted.gender},on:{"click":function($event){return _vm.handleClickAdjust('gender')}}},[_vm._v("\n                "+_vm._s(_vm.isAdjusted.gender ? 'Adjusted' : 'Adjust')+"\n            ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{class:[
            _vm.genBem(_vm.blockCls, 'question-wrapper'),
            _vm.genBem(_vm.blockCls, 'translator_level')
         ]},[_c('p',{class:_vm.getQuestionCls('translator_level')},[_vm._v("\n            "+_vm._s(_vm.$t('BOOKING_SPECIFICS.TRANSLATOR_LEVEL'))+"\n        ")]),_vm._v(" "),_c('p',{class:_vm.getAnswerCls('translator_level')},[_c('span',{class:_vm.getIconCls('translator_level')}),_vm._v("\n            "+_vm._s(_vm.getAnswer('translator_level'))+"\n\n            "),(_vm.getValue('translator_level'))?_c('el-button',{class:_vm.getButtonCls('translator_level'),attrs:{"type":"primary","size":"mini","disabled":_vm.isAdjusted.translator_level},on:{"click":function($event){return _vm.handleClickAdjust('translator_level')}}},[_vm._v("\n                "+_vm._s(_vm.isAdjusted.translator_level ? 'Adjusted' : 'Adjust')+"\n            ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{class:_vm.genBem(_vm.blockCls, 'physical')},[_c('p',{class:_vm.genBem(_vm.blockCls, 'physical__question')},[_vm._v("\n            "+_vm._s(_vm.$t('BOOKING_SPECIFICS.PHYSICAL.INDEX'))+"\n        ")]),_vm._v(" "),_c('p',{class:_vm.genBem(_vm.blockCls, 'physical__answer')},[_vm._v("\n            "+_vm._s(_vm.$t(`BOOKING_SPECIFICS.PHYSICAL.${_vm.specifics.physical_consideration.toUpperCase()}`))+"\n        ")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }