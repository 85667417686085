/*
|--------------------------------------------------------------------------
| Store > Booking > State
|--------------------------------------------------------------------------
|
| This file contains the state property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
'use strict';

import {
  gep
} from "~/js/helpers/Vuex";
import props from "./props";

let confirmFillsFresh = {
  booker_name: '',
  reference: '',
  staff_name: '',
  email: [],
  fax: '',
  faxEnabled: false,
  changeAddress: false,
  sms: '',
  translator_user: '',
  alternative_languages: [],
  address: '',
  city: '',
  instructions: '',
  notification_types: [],
  fallback_type: null,
  video_provider: 'jitsi',
  video_url: '',
  created_via: 'DT-Admin web',
  descriptions: '',
  is_express: 0,
  deliver_with: 'email',
  is_stamped: 0,
  is_confidential: 0,
  phone: '',
  customer_id: '',
  key: '',
  due: '',
  client_phone: '',
  message: "",
  message_files: [],
}

let defaultBookingFields = {
    same_as_municipality: true,
    staff_name_enabled: false,
    staff_name_required: false,
    staff_name_class: 'label-medium grey',
    booker_name_enabled: false,
    booker_name_required: false,
    booker_name_class: 'label-medium grey',
    lma_class: 'label-medium grey',
    reference_enabled: false,
    reference_required: false,
    reference_class: 'label-medium grey',
    reference_placeholder: '',
    instructions_enabled: false,
    instructions_required: false,
    alt_language_enabled: false,
    alt_language_required: false,
    // Booking Questions > LMA
    lma_enabled: false,
    lma_required: false,
}

let apiPrefix = 'v3'
export default {
  /**
   * Array of bookings data
   * @type {array|null}
   */
  bookings: [],
  /**
   * Array of user inside booking
   */
  usersInsideBooking: [],

  /**
   * booking object
   * @type {object|null}
   */
  booking: {
    id: '',
    isSpecificTranslator: false,
    isSpecificTranslatorReject: false,
    status: {
      id: '',
      name: ''
    },
    assigned_translator_id: '',
    translator_levels: [],
    alternative_languages_names: '',
    assigned_translator: null,
    customer: {
      email: '',
      id: '',
      customer_data: {
        department: {
          id: '',
          name: '',
          company: {
            name: ''
          }
        },
        customer_type: {
          name: ''
        }

      }
    },
    feedbacks: [],
    traveltime: [],
    towns: [],
    is_mentor_job: false,
    lma: '',
    session_comment: '',
    session_comment_translator: '',
    translator_already_travelled: false,
    is_teacher_job: false,
    is_evaluator_job: false
  },
  item: {
    data: {
      status: {
        id: '',
        name: ''
      },
      assigned_translator_id: '',
      translator_levels: [],
      alternative_languages_names: '',
      assigned_translator: {
        translator_id: null
      },
      customer: {
        email: '',
        id: '',
        customer_data: {
          department: {
            id: '',
            name: '',
            company: {
              name: ''
            }
          },
          customer_type: {
            name: ''
          }

        }
      },
      feedbacks: [],
      traveltime: []
    }
  },
  loading: false,
  error: {
    status: false,
    message: ''
  },
  /**
   * Container For Booking form object
   */
  bookingForm: {
    company_id: '',
    department_id: '',
    customer_id: '',
    gender: '',
    date: '',
    is_immediate: 0,
    time: '',
    duration: '',
    type: 'phone',
    from_language_id: '',
    translator_levels: ['layman', 'certified', 'certified_health', 'certified_law', 'read_courses'],
    specific_translators: [],
    exclude_translators: [],
    assigned_translator: '',
    assigned_translator_id: '',
    convey: {
      message: '',
      phone: '',
      email: '',
      files: [],
      additional_info: ''
    },
    convey_files: [],
    video_provider: 'jitsi',
    video_url: '',
    deliver_with: 'email',
    is_express: 0,
    is_stamped: 0,
    is_confidential: 0,
    text_translation_files: [],
    language_pair_levels: ['deluxe', 'non-certified', 'certified'],
    descriptions: '',
    search_customer_by: 'name',
    messages: {
      id: '',
      booking_id: '',
      message: '',
      files:[]
    },
    client_phone: '',
    message: "",
    message_files: [],
  },

  editBookingForm: _.cloneDeep(props),
  emptyEditBookingForm: _.cloneDeep(props),

  /**
   * Emergency booking state
   * @type {boolean|int}
   */
  is_immediate: 0,

  /**
   * Array of Translator types
   */
  translator_types: [],
  translatorLevels: [],

  /**
   * Customers list
   */
  customersList: [],
  translatorsList: [],
  departmentsList: [],
  companiesList: [],

  api: {
    browse: {
      method: 'get',
      path: gep('bookings', apiPrefix),
      params: {
        'include': 'alternative_languages,customer,specific_translators,specific_translators.translator,user_in_booking.user,locked_booking.user',
        'append': 'has_batches,translators_count,translators_sendable_count,translators_non_sendable_count'
      },
      data: {}
    },
    read: {
      method: 'get',
      path: gep('bookings/{id}', apiPrefix),
      params: {},
      data: {}
    },
  },
  customer: {
    name: '',
    id: '',
    email: '',
    department: {
      id: '',
      name: ''
    }
  },
  confirmationFills: _.cloneDeep(confirmFillsFresh),
  confirmationFillsFresh: _.cloneDeep(confirmFillsFresh),
  bookingField: _.cloneDeep(defaultBookingFields),
  bookingFieldFresh: _.cloneDeep(defaultBookingFields),
  availableTranslators: 0,
  bookerNameList: [],
  staffNameList: [],
  referenceList: [],
  emailList: [],
  selectedTranslatorLevels: [],

  statusList: [{
      id: 1,
      code: 'pending',
      name: 'Pending'
    },
    {
      id: 2,
      code: 'assigned',
      name: 'Assigned'
    },
    {
      id: 3,
      code: 'started',
      name: 'Started'
    },
    {
      id: 4,
      code: 'completed',
      name: 'Completed'
    },
    {
      id: 5,
      code: 'cancelled',
      name: 'Cancelled'
    },
    /* {
      id: 6,
      code: 'late_cancelled',
      name: 'Late cancelled'
    }, */
    {
      id: 7,
      code: 'compensation',
      name: 'Compensation'
    },
    {
      id: 8,
      code: 'expired',
      name: 'Expired'
    },
    {
      id: 9,
      code: 'not_carried_out_translator',
      name: 'Not carried out translator'
    },
    {
      id: 10,
      code: 'not_carried_out_customer',
      name: 'Not carried out customer'
    },
    {
      id: 11,
      code: 'temporary_pending',
      name: 'Temporary pending'
    },
    /* {
      id: 12,
      code: 'late_cancelled_customer',
      name: 'Late cancelled for customer'
    }, */
    {
      id: 13,
      code: 'late_cancelled_customer_translator',
      name: 'Late cancelled for customer and translators'
    },
    {
      id: 14,
      code: 'to_be_planned',
      name: 'To Be Planned'
    },
    {
      id: 15,
      code: 'planned',
      name: 'Planned'
    },
    {
      id: 16,
      code: 'offered',
      name: 'Offered'
    }
  ],
  histories: [],
  historiesAudit: [],
  historiesAuditBackup: [],
  departmentsListLoading: false,
  customersListLoading: false,
  expiringBookings: [],
  comments: [],
  locked_booking: [],
  assignedTranslator: {
    name: '',
    type: 0,
    email: '',
    username: '',
    gender: '',
    dob: '',
    mobile: '',
    phone: '',
  },
  usersAll: [],
  traveltime: [],
  currentPending: {
    count: 0,
    percentage: 0
  },
  currentAccepted: {
    count: 0,
    percentage: 0
  },
  totalCompleted: {
    count: 0,
    percentage: 0
  },
  totalCancelled: {
    count: 0,
    percentage: 0
  },
  lateCancelled: {
    count: 0,
    percentage: 0
  },
  notCarriedOutTranslator: {
    count: 0,
    percentage: 0
  },
  notCarriedOutCustomer: {
    count: 0,
    percentage: 0
  },

  lateWithdrawal: {
    count: 0,
    percentage: 0
  },
  lateWithdrawalList: [],
  isSpecificTranslator: false,
  isSpecificTranslatorReject: false,
  towns: [],
  newDepartmentAndUser: {
    name: '',
    email: '',
    address: '',
    city: '',
    department_id: '',
    generate_general_login: false,
    send_email_to_municipality: false,
    send_email_to_user: false,
    allow_send_invoices: false,
    allow_show_invoices: false,
    allow_fallback_type: false,
    allow_sms_confirmation: false,
    show_confirm_btn: true
  },
  userLockedBookings: [],
  lockedBookingUser: {},

  model_relationships: [
    // 'customer',
    'translatorLevels',
    'specificTranslators',
    'excludedTranslators',
    // 'translators',
    'assignedTranslator',
    'notificationTypes',
    'alternativeLanguages',
    'feedback',
    'lateAcceptAttempts',
    // 'booker',
    'videoBooking',
    'comments',
    // 'batches',
    // 'batchEntries',
    'towns',
    // 'town',
    // 'travelCalculation',
    'specificPreference',
    // 'bookingResources',
    'bookingResourceAssignments',
    // 'lockedBooking',
    'convey',
    'messages',
    // 'userInBooking',
    //'batchNotificationMessages'
    'withdrawnTranslators',
    'confirmationFills',
    'bookingTowns',
    'bookingLanguagePairLevel',
    'textTranslationFiles',
    'textTranslation',
    'sentBookingEmails',
    'files'
  ],
  reasons: []


}
