<template>
  <div class="app-page dashboard-page">
    <page-header :title="$t('dashboard')" />

    <div class="text-right" style="margin-top: -36px; margin-right: 2%;">
      {{ $t("include_test_booking") }}
      <el-switch
        v-model="includeTest"
        @change="handleChange($event)"
      ></el-switch>
    </div>

    <div class="app-container">
      <div class="app-wrapper">
        <div class="app-row">
          <div
            v-for="(v, k) in countList"
            :key="k"
            class="col-container quarter-width simple-count-display-container"
          >
            <simple-count-display v-model="counts[k].count" :title="k" />
          </div>
          <div
            class="col-container"
            style="width: 100%; height: 10px; background-color: transparent"
          ></div>
        </div>
        <!-- /.app-row -->
        <div class="app-row">
          <div
            class="app-col col-container col-header full-width"
            style="margin-top: 5px;"
          >
            {{ $t("dashboard_last_statistic_update") }}:
            {{ last_statistic_update }}, {{ $t("dashboard_interval") }}:
            {{ update_interval }} {{ $t("minutes") }}
          </div>
        </div>

        <div class="app-row">
          <div
            class="app-col col-container col-header full-width"
            style="margin-top: 25px;"
          >
            <h3 style="margin-bottom: 0;">
              <strong>{{ $t("bookings_locked_by_you") }}</strong>
            </h3>
          </div>
        </div>
        <!-- /.app-row -->
        <div class="app-row">
          <div class="app-col full-width">
            <dashboard-user-blocked-booking />
          </div>
        </div>
        <!-- /.app-row -->

<!--        <div class="app-row">-->
<!--          <div-->
<!--            class="app-col col-container col-header full-width"-->
<!--            style="margin-top: 25px;"-->
<!--          >-->
<!--            <h3 style="margin-bottom: 0;">-->
<!--              <strong>{{ $t("customer_made_bookings") }}</strong>-->
<!--            </h3>-->
<!--            <h4 style="margin-top: 0; margin-bottom: 10px;">-->
<!--              {{ dateToday }}-->
<!--            </h4>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash; /.app-row &ndash;&gt;-->

<!--        <div v-observe-visibility="dashboardCircleVisibility" class="app-row">-->
<!--          <div class="app-col col-container half-width">-->
<!--            <dashboard-circle-gauge-display-->
<!--              v-if="isVisibleDashboardCircles"-->
<!--              v-model="gauges.gauge1"-->
<!--              chart-color="#59BBBD"-->
<!--              title="Ersta Diakoni"-->
<!--            />-->
<!--          </div>-->

<!--          <div class="app-col col-container half-width">-->
<!--            <dashboard-circle-gauge-display-->
<!--              v-if="isVisibleDashboardCircles"-->
<!--              v-model="gauges.gauge2"-->
<!--              chart-color="#D79759"-->
<!--              title="Folktandvarden"-->
<!--            />-->
<!--          </div>-->

<!--          <div class="app-col col-container half-width">-->
<!--            <dashboard-circle-gauge-display-->
<!--              v-if="isVisibleDashboardCircles"-->
<!--              v-model="gauges.gauge3"-->
<!--              chart-color="#D7565D"-->
<!--              title="Haninge kommun"-->
<!--            />-->
<!--          </div>-->

<!--          <div class="app-col col-container half-width">-->
<!--            <dashboard-circle-gauge-display-->
<!--              v-if="isVisibleDashboardCircles"-->
<!--              v-model="gauges.gauge4"-->
<!--              chart-color="#333333"-->
<!--              title="Others"-->
<!--            />-->
<!--          </div>-->

<!--          <div-->
<!--            class="app-col col-container full-width"-->
<!--            style="padding-top: 10px"-->
<!--          ></div>-->
<!--        </div>-->
<!--        &lt;!&ndash; /.app-row &ndash;&gt;-->

<!--        <div class="app-row">-->
<!--          <div-->
<!--            class="app-col col-container col-header full-width"-->
<!--            style="margin-top: 25px;"-->
<!--          >-->
<!--            <h3 style="margin-bottom: 0;">-->
<!--              <strong>{{ $t("customer_made_bookings") }}</strong>-->
<!--            </h3>-->
<!--            <h4 style="margin-top: 0; margin-bottom: 10px;">-->
<!--              {{ dateToday }}-->
<!--            </h4>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash; /.app-row &ndash;&gt;-->

<!--        <div class="app-row">-->
<!--          <div class="app-col full-width">-->
<!--            <dashboard-company-report-display />-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash; /.app-row &ndash;&gt;-->
      </div>
      <!-- /.app-wrapper -->
    </div>
    <!-- /.app-container -->
  </div>
  <!-- /.app-page dashboard-page -->
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      includeTest: false,
      isVisibleDashboardCircles: false,

      countList: {
        session_today: "Sessions",
        bookings_made: "Bookings Made",
        cancels_today: "Cancels",
        to_expire: "To Expire",
        pending_today: "Pending",
        expired_today: "Expired",
        // expired_due_to_preference: "Expired Due To Preference",
        bookings_today: "Bookings Today",
        ave_feedback: "Av Feedback Today",
        translators_joined: "Translators Joined"
      }
    };
  }, // End of Component > data

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    ...mapState("dashboard", [
      "counts",
      "gauges",
      "last_statistic_update",
      "update_interval"
    ]),

    /**
     * Returns the date today in desired format.
     * @return {string}
     */
    dateToday: () => moment().format("MMMM D, YYYY"),

    translatorsAvailableUrl() {
      return `/translators-available-today`;
    }
  }, // End of Component > computed

  /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
  methods: {
    ...mapActions("dashboard", ["loadCountRequests"]),

    /**
     * Khegi's implementation of handler when the
     * include booking switch has changed it's value.
     *
     * @return {void}
     */
    handleChange(e) {
      this.loadCountRequests({ include_test: e });
    },

    dashboardCircleVisibility(isVisible) {
      this.isVisibleDashboardCircles = isVisible;
      console.log(`Is it visible: ${isVisible}`);
    }
  }, // End of Component > methods

  /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
  mounted() {
    this.loadCountRequests();
  } // End of Component > mounted
}; // End of export default
</script>
