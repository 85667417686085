import { render, staticRenderFns } from "./ViewEmailComponents.vue?vue&type=template&id=4ddeb364&scoped=true&"
import script from "./ViewEmailComponents.vue?vue&type=script&lang=js&"
export * from "./ViewEmailComponents.vue?vue&type=script&lang=js&"
import style0 from "./ViewEmailComponents.vue?vue&type=style&index=0&id=4ddeb364&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ddeb364",
  null
  
)

export default component.exports