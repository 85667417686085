<template>
  <div class="app-row">
    <div class="app-col full-width" v-if="!empty(user)">
      <template v-if="parseInt(user.type) === 3">

        <el-row>
          <el-col :md="6">
            <el-upload
              class="avatar-uploader"
              :class="{'no-photo': !userHasPhoto}"
              ref="upload"
              :show-file-list="false"
              :data="data"
              :auto-upload="true"
              accept="image/jpeg"
              action=""
              :before-upload="beforeAvatarUpload"
              :http-request="handleAvatarUpload">

              <img v-if="userHasPhoto"
                   :src="filePath + user[userDataKey]['photo']"
                   :alt="filePath + user[userDataKey]['photo']"
                   class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <span v-if="!userHasPhoto" class="hint">
                                {{$t('click_select_upload_image')}}
                            </span>
            </el-upload>
          </el-col>
          <el-col :md="18">
            <h3>{{ user.name }}</h3>
            <span>{{ (parseInt(user.type) === 2)? this.$t('customer') : this.$t('translator') }}</span>
            <el-row style="margin-bottom: 0;">
              <el-col>
                <el-button type="primary" @click="submitUpload" v-if="false">
                  <span class="fa fa-upload" style="margin-right: 5px;"></span>
                  {{$t('upload_profile_picture')}}
                </el-button>
                <el-button
                  type="primary"
                  @click="handleClickEditInfo"
                >
                  <span class="fa fa-pencil" style="margin-right: 5px;"></span>
                  {{$t('edit_Info')}}
                </el-button>
                <el-button type="primary" @click="handleLoginAs()">
                  <span class="fa fa-sign-in" style="margin-right: 5px;"></span>
                  {{$t('login_as_user')}}
                </el-button>
                <el-button type="primary" @click="gdprModal()">
                  <span style="margin-right: 5px;"></span>
                  {{$t('export_gdpr_data')}}
                  <send-gdpr-data :type="type" :user="user" :visible.sync="showExportGDPR"
                                  :width="gdprModalWidth"></send-gdpr-data>

                </el-button>
                <el-button type="primary" @click="handleRedirectAudits">
                  {{$t('audit_logs')}}
                </el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-button
                  type="primary"
                  class="btn-o"
                  @click="$router.push({name:'user.profile',id:user.id,hash:'#future',query:{type:3}})"
                >{{$t('future_bookings')}}
                </el-button>
                <el-button
                  type="primary"
                  class="btn-o"
                  @click="$router.push({name:'user.profile',id:user.id,hash:'#previous',query:{type:3}})"
                >{{$t('all_bookings')}}
                </el-button>
                <!--<el-button type="primary" class="btn-o">{{$t('temporary_locations')}}</el-button>-->
                <el-button type="primary" class="btn-o"
                           @click="$router.push({name:'user.profile',id:user.id,hash:'#unavailable',query:{type:3}})">
                  {{$t('unavailable_times')}}
                </el-button>
                <!--<el-button
                  type="primary"
                  class="btn-o"
                  size="mini"
                  @click="createCertificate"
                >{{$t('download_certificate')}}
                </el-button>
                <el-button
                  type="primary"
                  class="btn-o"
                  size="mini"
                  @click="sendInsuranceCertificate"
                >{{$t('send_insurance_certificate')}}
                </el-button>
                <el-button
                  type="primary"
                  class="btn-o"
                  size="mini"
                  @click="uploadModal"
                >{{$t('upload_files')}}
                </el-button>
                <upload-files-modal :type="type" :visible.sync="showLanguageOptions"
                                    :width="modalWidth"></upload-files-modal>
                <el-button
                  type="primary"
                  class="btn-o"
                  size="mini"
                  @click="sendSms"
                >{{$t('send_sms')}}
                </el-button>-->
                <el-button
                  type="primary"
                  class="btn-o"
                  size="mini"
                  @click="handleFinancialSettings"
                >{{$t('financial_info')}}
                </el-button>
                <!--<el-button
                  type="primary"
                  class="btn-o"
                  size="mini"
                  @click="sendPayrolls"
                >{{$t('send_last_payrolls')}}
                </el-button>-->
                <el-button
                  v-if="parseInt(user.type) === 3"
                  type="primary"
                  class="btn-o"
                  size="mini"
                  @click="handleClickWeeklyBookings">
                  {{$t('view_weekly_bookings')}}
                </el-button>
              </el-col>
              <el-col>

                <el-button v-if="parseInt(user.type) === 3"
                           type="primary"
                           class="btn-o"
                           size="mini"
                           @click="generateInsuranceCertificate('private-pdf')"
                >{{ $t('certificate_private_insurance') }}
                </el-button>
                <el-button v-if="parseInt(user.type) === 3"
                           type="primary"
                           class="btn-o"
                           size="mini"
                           @click="generateInsuranceCertificate('government-pdf')"
                >{{ $t('certificate_government') }}
                </el-button>

                <!--<el-button v-if="parseInt(user.type) === 3"
                           type="primary"
                           class="btn-o"
                           size="mini"
                           @click="openTemporaryNumberModal"
                >
                  {{ $t('temporary_number') }}
                </el-button>-->

              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </template>
      <el-row>
        <el-col>
          <div>
            <h3>{{ $t('tfv_profile_info') }}</h3>
            <template v-if="(parseInt(user.type) === 2)">
              <div>
                <el-button
                  type="primary"
                  @click="handleClickEditCustomer"
                >{{$t('edit')}}
                </el-button>
                <el-button type="primary" @click="handleLoginAs()">
                  <span class="fa fa-sign-in" style="margin-right: 5px;"></span>
                  {{$t('login_as_user')}}
                </el-button>
                <el-button type="primary" @click="handleRedirectAudits">
                  {{$t('audit_logs')}}
                </el-button>
                <!--<el-button
                  type="primary"
                  @click="sendSms"
                >{{$t('send_sms')}}
                </el-button>-->
              </div>
            </template>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="4">{{$t('user_id')}}</el-col>
        <el-col :md="8">{{user.id}}</el-col>
        <template v-if="parseInt(user.type) === 3">
          <el-col :md="12">
            <el-tag type="danger"
                    v-if="user.translator_data.is_protected_identity == 1 || user.translator_data.is_sensitive_info == 1">
              Confidential Profile
            </el-tag>
          </el-col>
        </template>
      </el-row>
      <el-row>
        <el-col :md="4">{{$t('name')}}</el-col>
        <el-col :md="8">{{user.name}} {{ user.notes == null ? '' : user.notes }}</el-col>
        <template v-if="parseInt(user.type) === 3">
          <el-col :md="4">{{$t('translator_type')}}</el-col>
          <el-col
            :md="8"
          >{{user.translator_customer_types | getNameValue(customerTypeOpts)}}
          </el-col>
        </template>
        <template v-if="parseInt(user.type) === 2">
          <el-col :md="4">{{$t('customer_type')}}</el-col>
          <el-col
            :md="8"
          >{{(user[userDataKey].customer_type) ? user[userDataKey].customer_type.name:''}}
          </el-col>
        </template>

      </el-row>
      <el-row>
        <el-col :md="4">{{$t('email')}}</el-col>
        <el-col :md="8">{{user.email}}</el-col>
        <template v-if="parseInt(user.type) === 3">
          <el-col :md="12">{{$t('translator_levels')}}
            <el-row style="margin-top:10px"
                    v-for="(items, index) in filterLanguageWithTranslatorLevelId(user.languages)" :key="index"
                    :gutter="20">
              <el-col :span="6" style="text-align:right"> {{items.name.name}}:</el-col>
              <el-col :span="16">{{joinTranslatorLevels(items.data)}}</el-col>
            </el-row>
          </el-col>
        </template>

      </el-row>
      <el-row>
        <el-col :md="4">{{$t('status')}}</el-col>
        <el-col :md="8">{{ (user.enabled) ? $t('enabled') : $t('disabled')}}</el-col>
        <el-col :md="4">{{$t('address')}}</el-col>
        <el-col :md="8">{{(user[userDataKey]) ? user[userDataKey]['address'] :''}}</el-col>

      </el-row>
      <el-row>
        <el-col :md="4">{{ $t('tfv_dob_organization') }}</el-col>
        <el-col :md="8">{{ user.dob}}</el-col>
        <el-col :md="4">{{$t('address')}} 2</el-col>
        <el-col :md="8">{{(user[userDataKey]) ? user[userDataKey]['address2'] :''}}</el-col>

      </el-row>
      <el-row>
        <el-col :md="4">{{$t('mobile')}}</el-col>
        <el-col :md="8">{{ user.mobile}}</el-col>
        <el-col :md="4">{{$t('town')}}</el-col>
        <el-col :md="8">{{(user[userDataKey]) ? user[userDataKey]['town'] :''}}</el-col>

      </el-row>
      <el-row>
        <el-col :md="4">{{$t('telephone')}}</el-col>
        <el-col :md="8">{{ user.phone}}</el-col>
        <el-col :md="4">{{$t('post_code')}}</el-col>

        <el-col :md="8">{{(user[userDataKey]) ? user[userDataKey]['post_code'] :''}}</el-col>
      </el-row>
      <el-row>
        <template v-if="parseInt(user.type) === 3">
          <el-col :md="4">{{$t('languages')}}</el-col>
          <el-col :md="8">{{ user.languages | getNameValue(languageOpts,'language_id')}}</el-col>
        </template>
        <el-col :md="4">{{$t('additional_information')}}</el-col>
        <el-col :md="8">{{(user[userDataKey]) ? user[userDataKey]['additional_info'] :''}}</el-col>
      </el-row>
      <el-row>
        <el-col :md="4">{{$t('created_at')}}</el-col>
        <el-col :md="8">{{user.created_at}}</el-col>
        <template v-if="parseInt(user.type) === 3">
          <el-col :md="4">{{$t('blocked_by')}}</el-col>
          <el-col :md="8"></el-col>
        </template>
      </el-row>
      <template v-if="parseInt(user.type) === 3">
        <div v-if="!empty(court_order)">
          <template v-if="court_order.answer_status==='danger'">
            <el-row style="color:red">

              <el-col :md="4">{{$t('police_check')}}</el-col>
              <el-col :md="8">{{$t('done')}} :{{court_order.date}} - {{court_order.answer_status}}
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="4">
                <el-button type="text" @click="getIssueLink()">{{$t('issue_details')}}</el-button>
              </el-col>
            </el-row>
          </template>
          <template v-else>
            <el-row>

              <el-col :md="4">{{$t('police_check')}}</el-col>
              <el-col :md="8">{{$t('done')}} :{{court_order.date}} - {{court_order.answer_status}}</el-col>
            </el-row>

          </template>
        </div>
        <!-- {{court_order}} -->
      </template>
      <temporary-number-modal :visible.sync="showTemporaryModal"/>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters} from "vuex";
  import {isEmpty} from "~/js/helpers/Common";
  import {gep} from "~/js/helpers/Vuex";
  import APICaller from "../../../js/helpers/APICaller";
  import {Notification} from "element-ui";
  import i18n from "../../../js/i18n";
  import {mapActions} from "vuex";
  import {sd} from "../../../js/helpers/Common";
  import USER_TYPES from '~/js/constants/user-types';
  import API from '~/js/constants/api';
  import {populate} from "../../../js/helpers/Vuex";

  // import jsPDF from "jspdf";
  // import {fontBase64, imageBase64} from "../../forms/certificate/config";
  // import {Promise} from "q";

  export default {
    name: "userInfoPanel",


    props: {
      user: {
        type: Object,
        required: true,
      }
    },

    data() {
      return {
        showLanguageOptions: false,
        showTemporaryModal: false,
        showExportGDPR: false,
        modalWidth: '50%',
        gdprModalWidth: '30%',
        type: 'user',
        counts: 0,
        percentage: 10,
        data: {
          type: "jpeg",
          name: ""
        },
        court_order: {},
        issueLink: ''
      };
    },
    filters: {
      uniq(v, field) {
        if (!v) return "";
        return _.uniqBy(v, field);
      },
      mapValue(v, key, secondKey) {
        if (!isEmpty(v)) {
          if (!secondKey) return v.map(o => o[key]).join(", ");
          return v
            .map(o => o[key])
            .map(i => {
              if (isEmpty(i)) {
                return ''
              }
              return i[secondKey]
            })
            .join(", ");
        }
      },
      getNameValue(v, opts, key) {
        // let _this = this
        if (!isEmpty(v)) {
          let uniq = _.uniqBy(v, key);
          let value = uniq.map(o => o[key]);

          return _.map(value, o => {
            if (!isEmpty(opts)) {
              const found = window._.find(opts, x => x.id === o);
              return found ? found.name : "";
            }
          }).join(", ");
        }
      }
    },
    computed: {
      ...mapGetters("language", {
        languageOpts: "getLanguages"
      }),
      ...mapGetters("customerType", {
        customerTypeOpts: "listAsOptions"
      }),

      ...mapGetters('auth', ['accessToken']),
      ...mapGetters('bookingIssueSetting', {
        settingList: 'listData'
      }),
      ...mapGetters('translatorLevel', {
        translatorLevelOpts: 'listAsOptions',
      }),
      ...mapState('customer', {
        relsCustomer: 'relationships'
      }),
      ...mapState('translator', {
        relsTranslator: 'relationships',
      }),
      /**
       * Returns boolean for determining if the user has an existing profile photo.
       *
       * @returns {boolean}
       */
      userHasPhoto() {
        return !window._.isNil(this.user[this.userDataKey].photo);
      },

      imageUrl: {
        get() {
          return this.user.settings['photo'];
        },
        set(v) {
          this.$emit("input", v);
        }
      },

      filePath() {
        // https://api-gateway.digitaltolk.se/api/v3/storage/get?path=${user[userDataKey].photo}
        return app_api + gep(`storage/get?path=`, "v3");
      },

      /**
       * Returns the user's type from route query param "type"
       * @return {int}
       */
      queryType() {
        return !window._.isNil(this.$route.query) && !window._.isNil(this.$route.query.type) ?
          parseInt(this.$route.query.type) :
          0;
      },

      /**
       * Returns the user's data key for reference purpose.
       * @return {string}
       */
      userDataKey() {
        return this.queryType === 2 ? 'customer_data' : 'translator_data';
      },

      crimeIssueType() {
        return this.settingList.find(i => i.type === 'crime_check_up')
      }

    },
    watch: {
      showLanguageOptions: function (value) {
        if (value == false) {
          this.fileUpdated(value);
        }
      }
    },
    methods: {
      ...mapActions("auth", {
        loginAs: "loginAs"
      }),
      ...mapActions('translatorLevel', {
        loadTranslatorLevels: 'browseAsOptions'
      }),
      ...mapActions({
        translatorCourtOrder: 'translator/readCourtOrder',
        viewCourtFile: 'translator/generateOrderFileLink',
        issueType: 'bookingIssueSetting/browse',
        courtIssue: 'translator/courtIssue'
      }),
      empty(v) {
        return isEmpty(v);
      },
      uploadModal() {
        this.showLanguageOptions = true;
      },
      gdprModal() {
        this.showExportGDPR = true;
      },
      fileUpdated(value) {
        this.$emit('clicked', value)
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },

      /**
       * Method to handle (or override) the upload process for el-upload.
       * @param payload
       */
      async handleAvatarUpload(payload) {
        if (this.userHasPhoto) {
          const opts = {
            method: 'DELETE',
            url: `${API.BASE}${API.USERS}/${this.$route.params.id}/photo`,
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Authorization': `Bearer ${this.accessToken}`,
            }
          }
          let res = await window.axios(opts)
        }
        let formData = new FormData();
        formData.append('name', payload.data.name);
        formData.append('type', payload.data.type);
        formData.append(payload.filename, payload.file);

        const apiOpts = {
          method: 'POST',
          url: `${API.BASE}${API.USERS}/${this.$route.params.id}/photo`,
          data: formData,
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': `Bearer ${this.accessToken}`,
            'Content-Type': 'multipart/form-data'
          }
        };

        let r = await window.axios(apiOpts)
          .then((r) => {
            this.user[this.userDataKey].photo = r.data.data.file.path;
          });

      },

      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        this.data = {
          type: file.type,
          name: file.name
        };
        if (!isLt2M) {
          this.$message.error("Avatar picture size can not exceed 2MB!");
        }
        return isLt2M;
      },
      submitUpload() {
        this.$refs.upload.submit();
      },
      createCertificate() {
        const adminData = JSON.parse(localStorage.getItem("app_user_data"));
        let xhr = new XMLHttpRequest();

        const queryParams = [
          'path=certificate.pdf',
          `admin_name=${adminData.name}`,
          `dob=${this.user.dob}`,
          `created_at=${window.moment(this.user.created_at).format('YYYY-MM-DD')}`,
          `name=${this.user.name}`
        ].join('&');

        xhr.open("GET", `${process.env.MIX_APP_API}/api/v3/generate/certificate?${queryParams}`, true);
        xhr.setRequestHeader(
          "Authorization",
          "Bearer " + localStorage.getItem("app_access_token")
        );
        xhr.onload = function () {
          if (this.status === 200) {
            if (this.status === 200) {
              let link = document.createElement("a");
              let url = JSON.parse(this.response).url;
              window.open(url)
            }
          }
        };
        xhr.send();
      },
      sendPayrolls() {
        const target = `translators/${
          this.$route.params.id
        }/send-payrolls?months_ago=14`;
        const apiPrefix = "v3";
        APICaller({
          method: "post",
          endpoint: gep(target, apiPrefix),
          headers: {
            'Accept': 'application/pdf',
          },
          data: {
            email: this.user.email
          }
        })
          .then(response => {
            if (response.status === 200) {
              Notification.success({
                title: "Success",
                message: "Send Payroll Sucessfully"
              });
            }
          })
          .catch(() => {
            Notification.error({
              title: "Error",
              message: "Something Went Wrong"
            });
          });
      },
      generateInsuranceCertificate(type) {
        let requested_via = '';
        if (window.screen.availWidth === window.innerWidth) {
          requested_via = 'admin-desktop';
        } else {
          requested_via = 'admin-responsive';
        }

        const translator_id = this.$route.params.id;
        const target = "translators/" + translator_id + "/insurance-certificate?requested_via=" + requested_via + "&type=" + type;
        const apiPrefix = "v3";

        const opts = {
          method: 'GET',
          url: `${API.BASE}${gep(target, apiPrefix)}`,
          responseType: 'blob',
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'Accept': 'application/pdf',
            'Content-Type': 'application/pdf',
          }
        }
        window.axios(opts).then((response) => {
          console.log(response);
          let blob = new Blob([response.data], {type: "application/pdf"})
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = type + '-certificate.pdf';
          link.click()
        });
      },
      sendInsuranceCertificate() {
        const target = "insurance/certificate";
        const apiPrefix = "v3";
        APICaller({
          method: "post",
          endpoint: gep(target, apiPrefix),
          data: {
            date_of_joining: this.user.created_at,
            previous_year: window.moment()
              .subtract(1, "year")
              .format("YYYY"),
            translator_email: this.user.email,
            translator_first_name: this.user.name
              .split(" ")
              .slice(0, -1)
              .join(" "),
            translator_last_name: this.user.name
              .split(" ")
              .slice(-1)
              .join(" "),
            translator_mobile: this.user.mobile,
            translator_dob: this.user.dob
          }
        })
          .then(r => {
            console.log(r);
            this.$alert(
              `<ol style="padding-right:17px">
             <p>Intyget har ännu EJ skickats till A-kassan. Du måste nu göra ett par saker för att avklara detta.<p>
              <li>Logga in på https://arbetsgivarintyg.nu</li>
               <li>Välj intyget du nyligen skapat</li>
             <li>Klista in nedanstående text i fältet "+ Klicka här för att lägga till ytterligare information" under punkt 11</li>
            <br>
            <p style="font-style:italic">Frilansande tolkar har olika ersättning för varje uppdrag. Då tolken har gjort uppdrag hos ett stort antal kunder har ersättningen varierat för varje timme som arbete utförts. Blanketten fylls in enligt instruktioner från förbundet Vision som organiserar frilansade tolkar, och denna blankett kompletteras med detaljerad arvodesspecifikation med information om antal uppdrag samt ersättning för varje uppdrag som utförts och arvoderats för.</p>
             <br>
             <li>Kolla om du behöver justera datumet för perioden</li>
             <li>Signera av bank-id</li>
            <li><b>Viktigt</b> Säkerställ att vi skickar alla lönespecifikationer till tolken så att de kan ladda upp lönespecifikationer för sin A-kassa</li>
             </ol>
             `,
              "Success", {
                confirmButtonText: "OK",
                dangerouslyUseHTMLString: true
              }
            );
          })
          .catch(e => {
            Notification.error({
              title: i18n.t("error"),
              message: e.response.data.data
            });
          });
      },
      handleLoginAs() {
        console.log(this.user)
        this.loginAs({
          email: this.user.email
        })
          .then(response => {
            let params = `?access=${response.data.token}`;
            window.open(
              sd(
                process.env.MIX_APP_APP_CT_URL,
                "https://ct.digitaltolk.se/"
              ) +
              "login/as" +
              params,
              "_blank"
            );
          })
          .catch(error => {
            this.$notify.error({
              title: "Error",
              message: "Unable to Login"
            });
          });
      },

      openTemporaryNumberModal() {
        this.showTemporaryModal = true;
      },

      /**
       * Handler when the Edit Info button was clicked.
       *
       * @return {void}
       */
      handleClickEditInfo() {
        // Go to Customer / Translator Edit Page.
        const userType = window._.chain(USER_TYPES)
          .findKey((t) => t === this.queryType)
          .value().toLowerCase();

        this.$router.push({
          name: `user.edit.${userType}`,
          param: {
            id: this.user.id
          }
        });
      },

      /**
       * Handler whent the Weekly Bookings was clicked.
       *
       * @return {void}
       */
      handleClickWeeklyBookings() {
        // Go to Translator's Weekly Booking Page
        this.$router.push({
          name: 'user.translator.bookings.weekly',
          param: {
            id: this.user.id
          }
        });
      },
      getCourtOrder(id, type) {
        if (type === 3) {
          this.translatorCourtOrder({
            id: id,
          }).then(r => {

            this.court_order = r.data.data.order
            let date = window.moment(this.court_order.created_at.date).format('YYYY-MM-DD')
            this.court_order = Object.assign(this.court_order, {
              date: date
            })
          })
        }
      },
      getFileLink(val, type = 'file') {
        let params = val
        params.type = type
        this.viewCourtFile(params).then(r => {
        })
      },
      getIssueLink() {
        this.courtIssue({
          all: true,
          'filter[type]': this.crimeIssueType.id,
          'filter[translator_id]': this.user.id,
          sort: '-created_at'
        }).then((r) => {
          let issue = r.data.data.booking_issues

          this.$router.push({path: `/booking-issues/${issue[0].id}`})
        })
      },
      /**
       * Filtering langauage by translator levelID
       * Generating and final array with all necessary
       * fields inside
       */
      filterLanguageWithTranslatorLevelId(languages) {
        let filter = _.chain(languages)
          .groupBy("language_id").toPairs()
          .map(item => {
            return _.zipObject(["language_id", "data"], item);
          }).value()

        if (!isEmpty(this.languageOpts)) {
          filter.map((i) => {
            return i.name = _.find(this.languageOpts, (o) => {
              if (o.id == i.language_id) {
                return o.name
              }
            });

          })
        }
        filter.map((lang) => {
          return lang.data.map((data) => {
            let level = _.find(this.translatorLevelOpts, (o) => o.id == data.translator_level_id);
            return data.name = level.name
          })
        })
        return filter
      },
      /**
       * Joining translator level name
       */
      joinTranslatorLevels(item) {
        return item.map((i) => {
          return i.name
        }).join(', ')
      },

      handleRedirectAudits() {
        if (parseInt(this.user.type) === 2) {
          this.$router.push({
            name: 'audits',
            params: {
              id: this.$route.params.id,
              auditable: 'users'
            },
            query: {
              relationships: this.relsCustomer.join(',')
            }
          })
        } else {
          this.$router.push({
            name: 'audits',
            params: {
              id: this.$route.params.id,
              auditable: 'users'
            },
            query: {
              relationships: this.relsTranslator.join(',')
            }
          })
        }
      },
      handleClickEditCustomer() {
        const userType = window._.chain(USER_TYPES)
          .findKey((t) => t === this.queryType)
          .value().toLowerCase();

        this.$router.push({
          name: `user.edit.${userType}`,
          param: {
            id: this.user.id
          }
        });
      },
      /*sendSms() {
        let routeData = this.$router.resolve({
          name: 'messaging.notification.add',
          query: {
            recipient: this.user.mobile
          }
        });
        window.open(routeData.href, '_blank');
      },*/
      handleFinancialSettings() {
        this.$router.push({
          name: 'user.financial.settings.form',
          params: {
            id: this.$route.params.id
          }
        })
      }

    },
    mounted() {
      console.log(this.$route.query.type)
      populate(this, 'loadTranslatorLevels', 'translatorLevelOpts', {
        ref: 'Translator Level Listing',
      });
      this.issueType({all: true})

      // this.getCourtOrder(this.$route.params.id, this.$route.query.type)

    }
  };
</script>

<style lang="scss" scoped>

  ul.file-list {
    list-style-type: none;
  }

  .el-row {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    // height: 178px;
    display: block;
  }
</style>
