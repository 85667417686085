var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isBatchesValid)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],class:_vm.getBem(_vm.blockClass)},[_c('table',[_c('thead',[_c('tr',[_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-select-checkbox'),
        _vm.getBem(_vm.blockClass, 'col-first'),
      ]},[_c('el-checkbox',{on:{"change":_vm.handleChangeSelectAll},model:{value:(_vm.selectAllBatches),callback:function ($$v) {_vm.selectAllBatches=$$v},expression:"selectAllBatches"}})],1),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-translator-info'),
        _vm.getBem(_vm.blockClass, 'col-translator-info'),
      ]},[_c('span',[_vm._v(_vm._s(_vm.$t('translator_info')))])]),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-language'),
        _vm.getBem(_vm.blockClass, 'col-language'),
      ]},[_c('span',[_vm._v(_vm._s(_vm.$t('language')))])]),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-booking-type'),
        _vm.getBem(_vm.blockClass, 'col-booking-type'),
      ]},[_c('span',[_vm._v(_vm._s(_vm.$t('tfv_booking_type')))])]),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-level'),
        _vm.getBem(_vm.blockClass, 'col-level'),
      ]},[_c('translator-batch-list-table-header-title',{attrs:{"sort":_vm.iSort,"label":_vm.$t('tfv_translator_level'),"col":"translator_level_id","is-sortable":true},on:{"update:sort":function($event){_vm.iSort=$event},"click-sort":_vm.handleClickSort}})],1),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-rejected'),
        _vm.getBem(_vm.blockClass, 'col-rejected'),
      ]},[_c('translator-batch-list-table-header-title',{attrs:{"sort":_vm.iSort,"label":_vm.$t('rejected'),"col":"rejected_at","is-sortable":true},on:{"update:sort":function($event){_vm.iSort=$event},"click-sort":_vm.handleClickSort}})],1),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-last-job-accepted'),
        _vm.getBem(_vm.blockClass, 'col-last-job-accepted'),
      ]},[_c('translator-batch-list-table-header-title',{attrs:{"sort":_vm.iSort,"label":_vm.$t('last_job_accepted'),"col":"last_booking_due","is-sortable":true},on:{"update:sort":function($event){_vm.iSort=$event},"click-sort":_vm.handleClickSort}})],1),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-jobs-in-30-days'),
        _vm.getBem(_vm.blockClass, 'col-jobs-in-30-days'),
      ]},[_c('translator-batch-list-table-header-title',{attrs:{"sort":_vm.iSort,"label":_vm.$t('jobs_30_days'),"col":"previous_bookings_count","is-sortable":true},on:{"update:sort":function($event){_vm.iSort=$event},"click-sort":_vm.handleClickSort}})],1),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-distance'),
        _vm.getBem(_vm.blockClass, 'col-distance'),
      ]},[_c('translator-batch-list-table-header-title',{attrs:{"sort":_vm.iSort,"col":"temp_travel_distance_car","is-sortable":true},on:{"update:sort":function($event){_vm.iSort=$event},"click-sort":_vm.handleClickSort},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('distance'))+" "),_c('br'),_vm._v("\n            ("+_vm._s(_vm.$t('car'))+")\n          ")]},proxy:true}],null,false,504766718)})],1),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-time-car'),
        _vm.getBem(_vm.blockClass, 'col-time-car'),
      ]},[_c('translator-batch-list-table-header-title',{attrs:{"sort":_vm.iSort,"col":"temp_travel_time_car","is-sortable":true},on:{"update:sort":function($event){_vm.iSort=$event},"click-sort":_vm.handleClickSort},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('time'))+" "),_c('br'),_vm._v("\n            ("+_vm._s(_vm.$t('car'))+")\n          ")]},proxy:true}],null,false,4221002152)})],1),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-distance-public'),
        _vm.getBem(_vm.blockClass, 'col-distance-public'),
      ]},[_c('translator-batch-list-table-header-title',{attrs:{"sort":_vm.iSort,"col":"temp_travel_distance_public","is-sortable":true},on:{"update:sort":function($event){_vm.iSort=$event},"click-sort":_vm.handleClickSort},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('distance'))+" "),_c('br'),_vm._v("\n            ("+_vm._s(_vm.$t('public'))+")\n          ")]},proxy:true}],null,false,3050573935)})],1),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-time-public'),
        _vm.getBem(_vm.blockClass, 'col-time-public'),
      ]},[_c('translator-batch-list-table-header-title',{attrs:{"sort":_vm.iSort,"col":"temp_travel_time_public","is-sortable":true},on:{"update:sort":function($event){_vm.iSort=$event},"click-sort":_vm.handleClickSort},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('time'))+" "),_c('br'),_vm._v("\n            ("+_vm._s(_vm.$t('public'))+")\n          ")]},proxy:true}],null,false,2774163001)})],1),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-send-at'),
        _vm.getBem(_vm.blockClass, 'col-send-at'),
      ]},[_c('translator-batch-list-table-header-title',{attrs:{"sort":_vm.iSort,"label":_vm.$t('sent_at'),"col":"sent_at","is-sortable":true},on:{"update:sort":function($event){_vm.iSort=$event},"click-sort":_vm.handleClickSort}})],1),_vm._v(" "),_c('th',{class:[
        _vm.getBem(_vm.blockClass, 'header-action'),
        _vm.getBem(_vm.blockClass, 'col-action'),
      ]},[_vm._v("\n        "+_vm._s(_vm.$t('action'))+"\n      ")])])]),_vm._v(" "),_vm._l((_vm.batches),function(batch){return _c('tbody',{class:_vm.getBem(_vm.blockClass, 'batch-item')},[_c('tr',{class:_vm.getBem(_vm.blockClass, 'batch-item-header')},[_c('td',{class:[
            _vm.getBem(_vm.blockClass, 'batch-item-header-checkbox'),
            _vm.getBem(_vm.blockClass, 'col-first')
          ]},[_c('el-checkbox',{attrs:{"label":batch.id},model:{value:(_vm.selectedBatches),callback:function ($$v) {_vm.selectedBatches=$$v},expression:"selectedBatches"}},[_c('span')])],1),_vm._v(" "),_c('td',{class:_vm.getBem(_vm.blockClass, 'batch-item-header-id')},[_c('span',{attrs:{"title":"Batch ID"}},[_vm._v("\n          #"+_vm._s(batch.id)+"\n        ")])]),_vm._v(" "),_c('td',{class:_vm.getBem(_vm.blockClass, 'batch-item-header-will-send-at'),attrs:{"colspan":_vm.totalColumns - 2}},[(_vm.isBatchSendable(batch))?[_c('span',{class:_vm.getBem(_vm.blockClass, 'batch-item-header-will-send-at-label')},[_vm._v("\n              "+_vm._s(_vm.$t('will_send_at'))+"\n            ")]),_vm._v(" "),_c('span',{class:_vm.getBem(_vm.blockClass, 'batch-item-header-will-send-at-value')},[_vm._v("\n              "+_vm._s(batch.will_send_at)+"\n            ")])]:[_c('span',{class:_vm.getBem(_vm.blockClass, 'batch-item-header-non-sendable')},[_c('span',{staticClass:"fa fa-exclamation-triangle"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('non_sendable')))]),_vm._v(" "),_c('span',[_vm._v(" : "+_vm._s(_vm.getNonSendableReason(batch)))])])]],2)]),_vm._v(" "),_vm._l((batch.entries),function(entry){return [_c('tr',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry')},[_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-col-first'),
          _vm.getBem(_vm.blockClass, 'col-first'),
        ]},[_c('a',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-resend-notification'),attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleClickResendNotification(entry)}}},[_c('span',{staticClass:"fa fa-bell"})])]),_vm._v(" "),_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-translator-info'),
          _vm.getBem(_vm.blockClass, 'col-translator-info'),
        ]},[_c('div',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-translator-info-name'),attrs:{"title":"Translator Name"}},[_c('a',{attrs:{"href":`/#/user/profile/${entry.translator.id}?type=3`,"target":"_blank"}},[_vm._v("\n              "+_vm._s(entry.translator.name)+"\n            ")])]),_vm._v(" "),_c('div',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-translator-info-others')},[_c('span',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-translator-info-gender'),attrs:{"title":"Translator Gender"}},[_vm._v("\n              "+_vm._s(_vm.$t(`tfv_${entry.translator.gender}`))+"\n            ")]),_vm._v(" "),_c('el-rate',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-translator-info-rate'),attrs:{"disabled":"","colors":_vm.rateActiveColors,"void-color":_vm.rateVoidColor,"disabled-void-color":_vm.rateVoidColor,"title":"Translator Rating"},model:{value:(entry.average_rating),callback:function ($$v) {_vm.$set(entry, "average_rating", $$v)},expression:"entry.average_rating"}}),_vm._v(" "),_c('span',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-translator-info-notes'),attrs:{"title":"Translator Notes"}},[_vm._v("\n            "+_vm._s(entry.translator.notes)+"\n          ")])],1)]),_vm._v(" "),_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-language'),
          _vm.getBem(_vm.blockClass, 'col-language'),
        ]},[_c('span',{attrs:{"title":"Language"}},[_vm._v("\n            "+_vm._s(_vm.getLanguageName(entry.language_id))+"\n          ")]),_vm._v(" "),(entry.translator.mobile != null)?_c('span',{attrs:{"title":"Mobile"}},[_vm._v("\n            "+_vm._s(entry.translator.mobile)+"\n          ")]):(entry.translator.mobile == null)?_c('span',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-no-value')},[_vm._v("\n              "+_vm._s(_vm.$t('tfv_no_mobile'))+"\n          ")]):_vm._e()]),_vm._v(" "),_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-booking-type'),
          _vm.getBem(_vm.blockClass, 'col-booking-type'),
        ]},[_c('span',{attrs:{"title":"booking-type"}},[_vm._v("\n            "+_vm._s(_vm.$t(entry.booking_type))+"\n          ")])]),_vm._v(" "),_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-level'),
          _vm.getBem(_vm.blockClass, 'col-level'),
        ]},[_c('span',{attrs:{"title":"Translator Level"}},[_vm._v("\n            "+_vm._s(_vm.getTranslatorLevelName(entry.translator_level_id))+"\n          ")])]),_vm._v(" "),_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-rejected'),
          _vm.getBem(_vm.blockClass, 'col-rejected'),
        ]},[(entry.rejected_at != null)?[_c('el-tooltip',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-rejected-tooltip'),attrs:{"effect":"dark","content":_vm.getRejectedAtTooltipContent(entry),"placement":"top"}},[_c('span',[_vm._v(_vm._s(_vm.$t('yes')))])])]:[_c('span',{attrs:{"title":"Rejected At"}},[_vm._v(_vm._s(_vm.$t('no')))])]],2),_vm._v(" "),_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-last-job-accepted'),
          _vm.getBem(_vm.blockClass, 'col-last-job-accepted'),
        ]},[(entry.last_booking_due != null)?[_c('div',{attrs:{"title":"Last Booking Due"}},[_vm._v(_vm._s(entry.last_booking_due))])]:[_c('div',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-no-value')},[_vm._v("\n              "+_vm._s(_vm.$t('no_booking_due'))+"\n            ")])]],2),_vm._v(" "),_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-jobs-in-30-days'),
          _vm.getBem(_vm.blockClass, 'col-jobs-in-30-days'),
        ]},[(entry.previous_bookings_count != null)?_c('span',{attrs:{"title":"Last Jobs in 30 Days"}},[_vm._v("\n            "+_vm._s(entry.previous_bookings_count)+"\n          ")]):_c('span',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-no-value')},[_vm._v("\n            "+_vm._s(_vm.$t('no_last_job'))+"\n          ")])]),_vm._v(" "),_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-distance'),
          _vm.getBem(_vm.blockClass, 'col-distance'),
        ]},[(entry.temp_travel_distance_car != null)?_c('span',{attrs:{"title":"Distance (Car)"}},[_vm._v("\n            "+_vm._s(entry.temp_travel_distance_car)+"\n          ")]):_c('span',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-no-value')},[_vm._v("\n            "+_vm._s(_vm.$t('no_distance_car'))+"\n          ")])]),_vm._v(" "),_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-time-car'),
          _vm.getBem(_vm.blockClass, 'col-time-car'),
        ]},[(entry.temp_travel_time_car != null)?_c('span',{attrs:{"title":"Time (Car)"}},[_vm._v("\n            "+_vm._s(entry.temp_travel_time_car)+"\n          ")]):_c('span',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-no-value')},[_vm._v("\n           "+_vm._s(_vm.$t('no_time_car'))+"\n          ")])]),_vm._v(" "),_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-distance-public'),
          _vm.getBem(_vm.blockClass, 'col-distance-public'),
        ]},[(entry.temp_travel_distance_public != null)?_c('span',{attrs:{"title":"Distance (Public)"}},[_vm._v("\n            "+_vm._s(entry.temp_travel_distance_public)+"\n          ")]):_c('span',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-no-value')},[_vm._v("\n            "+_vm._s(_vm.$t('no_distance_public'))+"\n          ")])]),_vm._v(" "),_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-time-public'),
          _vm.getBem(_vm.blockClass, 'col-time-public'),
        ]},[(entry.temp_travel_time_public != null)?_c('span',{attrs:{"title":"Time (Public)"}},[_vm._v("\n            "+_vm._s(entry.temp_travel_time_public)+"\n          ")]):_c('span',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-no-value')},[_vm._v("\n            "+_vm._s(_vm.$t('no_time_public'))+"\n          ")])]),_vm._v(" "),_c('td',{class:[
          _vm.getBem(_vm.blockClass, 'batch-item-entry-send-at'),
          _vm.getBem(_vm.blockClass, 'col-send-at'),
        ]},[(entry.sent_at != null)?_c('span',{attrs:{"title":"Sent At"}},[_vm._v("\n            "+_vm._s(entry.sent_at)+"\n          ")]):_c('span',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-no-value')},[_vm._v("\n            "+_vm._s(_vm.$t('no_sent_at'))+"\n          ")])]),_vm._v(" "),_c('td',{class:[
        _vm.getBem(_vm.blockClass, 'batch-item-entry-actions'),
        _vm.getBem(_vm.blockClass, 'col-action'),
      ]},[_c('el-button',{attrs:{"disabled":entry.rejected_at != null},on:{"click":function($event){return _vm.handleClickRejected(entry)}}},[_vm._v("\n            "+_vm._s(_vm.$t('rejected'))+"\n          ")]),_vm._v(" "),(!entry.is_called)?[_c('el-button',{on:{"click":function($event){return _vm.handleClickNotPickUpCall(entry)}}},[_vm._v("\n              "+_vm._s(_vm.$t('not_pick_up_call'))+"\n            ")])]:[_c('el-tooltip',{class:_vm.getBem(_vm.blockClass, 'batch-item-entry-rejected-tooltip'),attrs:{"effect":"dark","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('span',[_vm._v(_vm._s(entry.last_called_at))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.lastCalledByName))])]),_vm._v(" "),_c('div',{staticClass:"el-button el-button--default is-disabled",on:{"mouseover":function($event){return _vm.getLastCalledByName(entry)}}},[_vm._v("\n                "+_vm._s(_vm.$t('not_pick_up_call'))+"\n              ")])])],_vm._v(" "),_c('el-button',{on:{"click":function($event){return _vm.handleClickAssign(entry)}}},[_vm._v("\n            "+_vm._s(_vm.$t('assign_button'))+"\n          ")])],2)]),_vm._v(" "),_c('translator-batch-list-table-comment-row',{attrs:{"parent-block-class":_vm.blockClass,"total-columns":_vm.totalColumns,"entry":entry,"placeholder":_vm.$t('write_comment')}})]})],2)})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }