// import APICaller from '~/js/helpers/APICaller';
// import {gep} from "~/js/helpers/Vuex";
import { isEmpty } from './Common';
import API from '~/js/constants/api';

/**
 * Helpers for handling get file to AWS storage through dt storage endpoint
 *
 * @params {object} o
 * @params {url} o.url
 *
 * @return {FILE}
 */
export async function download(payload) {
    let token = localStorage.getItem("app_access_token");

    let o = {
      params: `path=${payload.path}&display_name=${payload.display_name}`,
      filename: payload.display_name
    }

    let url = `${API.BASE}/api/v3/storage/get?${o.params}`;
    return await window.axios.get(url,{
        headers:{
            'Authorization': 'Bearer ' + token
        },
    }).then(r => {

        if (!isEmpty(r.data) && !isEmpty(r.data.data.url)) {
            window.open(r.data.data.url, '_blank');
        }

        // const link = document.createElement('a');

        // link.href = r.data.data.url;

        //     r.data.data.url;


        // // link.setAttribute('download', o.filename); //any other extension

        // document.body.appendChild(link);

        // link.click();

        // link.remove();
    })

}


/**
 * Helpers for handling stroe file to AWS storage through dt storage endpoint
 *
 * @params {FILE} file
 * @params {options} options
 * @params {options.url} options.url
 *
 * @return {object}
 */
export async function storeFile(file, options = {}) {

    let url = isEmpty(options.url) ? '/vapor/signed-storage-url' : options.url

    let token = localStorage.getItem("app_access_token");
    const response = await window.axios.post(url, {
        'bucket': options.bucket || '',
        'content_type': options.contentType || file.type,
        'expires': options.expires || '',
        'visibility': options.visibility || '',
    }, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });

    let headers = response.data.headers;
    headers['x-amz-acl'] = 'private'
    if ('Host' in headers) {
        delete headers.Host;
    }

    if (typeof options.progress === 'undefined') {
        options.progress = () => {};
    }

    await window.axios.put(response.data.url, file, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
            options.progress(progressEvent.loaded / progressEvent.total);
        }
    });
    response.data.extension = file.type
    // response.data.extension = file.name.split('.').pop()

    return response;
}

/**
 * Helper for getting the S3 details before upload.
 *
 * @returns {Promise<any> | Promise}
 */
function getSignedStorageDetails() {

  return new Promise((resolve, reject) => {

    const opts = {
      url: API.BASE + '/' + API.SIGNED_STORAGE_URL,
      method: 'POST',
      data: {
        // To make sure to point into the correct bucket.
        bucket: 'dtolkrequests'
      }
    };

    return window.axios(opts)
      .then((r) => {
        resolve(r.data);
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
}

/**
 * Helper for upload the file into the cloud.
 *
 * @param {Blob} file
 * @param {Object} cloudDetails
 * @returns {Promise<any> | Promise}
 */
function uploadToCloud(file, cloudDetails) {

  return new Promise((resolve, reject) => {

    const url = cloudDetails.url;
    if (url == null) throw new Error('Missing required value : url');

    const method = 'PUT';

    const data = file;
    if (data == null) throw new Error('File value cannot be null');

    let headers = {
      ...cloudDetails.headers
    };
    if ('Host' in headers) delete headers.Host; // Remove Host Key.
    if ('x-amz-acl' in headers) delete headers['x-amz-acl'];
    console.log("===HEADERS===")
    console.log(headers)

    return window.axios({
        url,
        method,
        data,
        headers
      })
      .then((r) => resolve(r))
      .catch((e) => reject(e));
  });
}

/**
 * Helper method for upload request data files
 *
 * @param {Blob} file
 * @returns {Promise<any> | Promise}
 */
export function uploadRequestData(file) {

  return new Promise((resolve, reject) => {

    return getSignedStorageDetails()
      .then((cloudDetails) => {

        uploadToCloud(file, cloudDetails)
          .then((r) => resolve({
            response: r,
            cloud_details: cloudDetails
          }))
          .catch((e) => reject(e));
      });
  });
}
