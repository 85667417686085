var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-display",class:_vm.genBem(_vm.blockCls)},[_c('div',{class:_vm.genBem(_vm.blockCls, 'wrapper')},[(_vm.chartHeaders.length > 0)?_c('div',{class:_vm.genBem(_vm.blockCls, 'header')},_vm._l((_vm.chartHeaders),function(v){return _c('div',{class:[
                    {'in-between': v.isInBetween},
                    _vm.genBem(_vm.blockCls, 'header-item')
                 ],style:(_vm.getHeaderItemStyle(v))},[_c('span',[_vm._v(_vm._s(v.label))])])}),0):_vm._e(),_vm._v(" "),(_vm.hasChartData)?_c('div',{class:_vm.genBem(_vm.blockCls, 'body')},_vm._l((_vm.chartData),function(group){return _c('div',{class:_vm.genBem(_vm.blockCls, 'item'),style:({height: _vm.barStyle.height + 'px'})},_vm._l((group.bookings),function(bar){return _c('div',{class:[
                        _vm.genBem(_vm.blockCls, 'bar'),
                        _vm.getBarCls(bar)
                     ],style:(_vm.getBarStyle(bar))},[_c('div',[_c('p',{class:_vm.genBem(_vm.blockCls, 'bar-id')},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.gotoBooking(bar.booking.id)}}},[_c('strong',[_vm._v("#"+_vm._s(bar.booking.id))])])]),_vm._v(" "),_c('p',{class:_vm.genBem(_vm.blockCls, 'bar-language-name')},[_c('strong',[_vm._v(_vm._s(bar.booking.language_name))])]),_vm._v(" "),_c('p',{class:_vm.genBem(_vm.blockCls, 'bar-range')},[_vm._v("\n                            "+_vm._s(_vm.formatTimeDisplay(bar.booking.start))+" -\n                            "+_vm._s(_vm.formatTimeDisplay(bar.booking.end))+"\n                        ")]),_vm._v(" "),_c('p',{class:_vm.genBem(_vm.blockCls, 'bar-type')},[_vm._v("\n                            "+_vm._s(_vm.$t(bar.booking.type))+"\n                        ")])])])}),0)}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }