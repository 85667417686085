/*
|--------------------------------------------------------------------------
| Store > Booking > Mutations
|--------------------------------------------------------------------------
|
| This file contains the mutations property of Auth Vuex Module
|
| You may freely extend this store file if the store file that you will
| be building has similar characteristics.
|
*/
import {isEmpty, setTimedLocalStorage} from '../../helpers/Common';


export default {

  setError: (state, payload) => state.error = payload,
  /**
   * Set Bookings list state
   *
   * @param {object} state
   *  : current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {array}
   */
  setBookings: (state, payload) => {
    let key = Object.keys(payload.data)[0];
    state.bookings = payload.data[key];
  },

/**
 *
 * Set Updated booking state for real time
 *
 */

  setUpdateEvent : (state, payload)  => {
    state.booking =  payload
  },

  /**
   * Set Booking object state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {object}
   */
  setBooking: (state, payload) => {

    let key = Object.keys(payload.data)[0];
    state.booking = payload.data[key];
    if (!isEmpty(state.booking.assigned_translator)) {
      state.booking.assigned_translator_id = state.booking.assigned_translator.translator_id;
      // context.commit('setTranslator',state.booking.assigned_translator.translator_id)
    }
    if (!isEmpty(state.booking.alternative_languages)) {
      state.booking.alternative_languages = state.booking.alternative_languages.map(v => v.language_id);
      // context.commit('setTranslator',state.booking.assigned_translator.translator_id)
    }

    if (!isEmpty(state.booking.towns)) {
      state.booking.batch_town =  state.booking.towns
      state.booking.towns = state.booking.towns.map(v => v.id);
      // context.commit('setTranslator',state.booking.assigned_translator.translator_id)
    }
    state.booking.isSpecificTranslator = !isEmpty(state.booking.specific_translators)

  },

  /**
   * Set Bookings form object
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {object}
   */
  setForm: (state, payload) => state.bookingForm = payload,

  /**
   * Set edit Bookings form object
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {object}
   */
  setEditForm: (state, payload) => {

    let key = Object.keys(payload.data)[0];
    let booking = payload.data[key];

    // Object.assign(state.editBookingForm,payload.data[key]);
    // state.editBookingForm.assigned_translator_id = '';
    // state.editBookingForm = payload.data[key];
    if (!isEmpty(booking.assigned_translator)) {
      booking.assigned_translator_id = booking.assigned_translator.translator_id
    }
    if (!isEmpty(booking.specific_translators)) {
      booking.specific_translators_name = booking.specific_translators.map((item) => {
               return item
      });
      booking.specific_translators = _.map(booking.specific_translators, 'translator_id');
    }
    if (!isEmpty(booking.excluded_translators)) {
      booking.excluded_translators = _.map(booking.excluded_translators, 'translator_id');
    }
    if (!isEmpty(booking.translator_levels)) {
      booking.translator_levels = _.map(booking.translator_levels, 'translator_level_id');
    }

    if (isEmpty(booking.comment)) {
      booking = Object.assign(booking, {comment: ''})
    }


    let emails = _.filter(booking.notification_types, ['type', 'email']);
    booking.email_notifications = _.map(emails, 'recipient')

    let fax = _.find(booking.notification_types, ['type', 'fax']);
    if (!isEmpty(fax)) {
      booking.fax_notification_enabled = true;
      booking.fax_notification = fax.recipient;
    }
    let sms = _.find(booking.notification_types, ['type', 'sms']);
    if (!isEmpty(sms)) {
      booking.sms_notification_enabled = true;
      booking.sms_notification = sms.recipient;
    }
    if (!isEmpty( booking.fallback_type)) {
      booking.allow_fallback_type = true;
    }

     if (!isEmpty(booking.language_pair_levels)) {
       booking.language_pair_levels = _.map(booking.language_pair_levels, 'code');
     }

    if (booking.type === 'video') {
      booking.video_provider = 'jitsi';
    }

    if (booking.type === 'video_skype') {
      booking.video_provider = 'skype';
      booking.video_url = !isEmpty(booking.video_booking) ? booking.video_booking.url : ''
    }

    if (isEmpty(booking.messages)) {
      booking.messages= {
        id: '',
        booking_id: booking.id,
        message: '',
        files: []
      }
    }


    state.editBookingForm = _.cloneDeep(booking)
  },

  resetEditBookingForm(state) {
    state.editBookingForm = _.cloneDeep(state.emptyEditBookingForm);
  },

  setAssignedTranslator: (state, payload) => {
    state.assignedTranslator = payload
  },
  /**
   * Set Emergency Bookings type
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {boolean}
   */
  setIsImmediate: (state, payload) => {
    state.is_immediate = payload;
    state.bookingForm.is_immediate = payload;
  },

  setGender: (state, payload) => {
    state.bookingForm.gender = payload;
  },
  /**
   * Reset Bookings form to default state
   *
   * @param {object} state
   * :current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {boolean}
   */
  resetForm: (state, payload) => {
    Object.assign(state.bookingForm, payload);
  },

  /**
   * set translators levels to translator type state
   * and map gender to it
   * @param {object} state
   * :current state of the vuex Object
   *
   *  @param {*} payload
   *    Value(s) passed to be used inside this method.
   *
   * @return {array}
   */
  setTranslatorLevels: (state, payload) => {

    let translatorLevel = payload.data.translator_levels.map(item => {
      return {
        id: item.id,
        name: item.name
      };
    });
    let gender = [
      {id: 'male', name: 'Male'},
      {id: 'female', name: 'Female'},
    ];
    state.translator_types = [...gender, ...translatorLevel]
    state.translatorLevels = translatorLevel
  },

  setCustomersList: (state, payload) => {
    let key = Object.keys(payload.data)[0];
    setTimedLocalStorage('customers',JSON.stringify(payload.data[key]));
    let customers = localStorage.getItem('customers')

    state.customersList = JSON.parse(customers);
  },
  setTranslatorsList: (state, payload) => {
    let key = Object.keys(payload.data)[0];

    state.translatorsList = payload.data[key];
  },
  setDepartmentsList: (state, payload) => {
    let key = Object.keys(payload.data)[0];

    state.departmentsList = payload.data[key];
  },
  setCompaniesList: (state, payload) => {
    let key = Object.keys(payload.data)[0];
    state.companiesList = payload.data[key];
  },
  setLoading: (state, payload) => {
    state.loading = payload
  },
  setDepartmentsListLoading: (state, payload) => {
    state.departmentsListLoading = payload
  },
  setCustomerListLoading: (state, payload) => {
    state.customersListLoading = payload
  },
  setConfirmationFills: (state, payload) => {state.confirmationFills = payload},
  setDefaultConfirmationFills: (state, payload) => {
    state.defaultConfrimationFills = payload;
  },
  setBookerNameList: (state, payload) => {
    state.confirmationFills.booker_name = _.last(payload)
    state.bookerNameList = payload;
  },
  setReferenceList: (state, payload) => {
    state.confirmationFills.reference = _.last(payload)
    state.referenceList = payload;
  },
  setStaffNameList: (state, payload) => {
    state.confirmationFills.staff_name = _.last(payload)
    state.staffNameList = payload;
  },

  setCustomer: (state, payload) => {
    Object.assign(state.customer, payload)
  },
  setCustomerDepartment: (state, payload) => {
    Object.assign(state.customer.department, payload.data.department)
  },
  setBookingCustomer: (state, payload) => {

    state.booking.customer_id = payload.name;
  },
  setCustomerfill: (state, payload) => {
    state.confirmationFills = payload

  },
  setTimeFormat: (state, payload) => {
    state.bookingForm.time = payload
  },
  setSelectedTranslatorLevels: (state, payload) => {
    state.booking.translator_levels = payload
  },
  setSelectedSpecificTranslators: (state, payload) => {
    state.booking.specific_translators = payload
  },

  setHistories: (state, payload) => {
    let key = Object.keys(payload.data)[0];
    state.histories = payload.data[key]
  },
  setHistoriesAudit: (state, payload) => {
    let audits = _.values(payload.audits);
    state.historiesAudit = audits.sort((a, b) => {
      let aDate = new Date(a.created_at);
      let bDate = new Date(b.created_at);
      return bDate - aDate;
    });
  },
  setHistoriesAuditBackup: (state, payload) => {
    // let key = Object.keys(payload.data);
    let audits =  _.values(payload.audits)
    state.historiesAuditBackup = audits.sort((a, b) => {
      let aDate = new Date(a.created_at);
      let bDate = new Date(b.created_at);
      return bDate - aDate;
    });
  },
  setExpiringBookings: (state, payload) => {
    let key = Object.keys(payload.data)[0];
    // console.log(key,payload.data[key])
    state.expiringBookings = payload.data[key]
  },

  setBookingStatusById: (state, {id, status}) => {
    state.list.data.map((item) => {
      if (item.id === id) {
        item.status_id = status.id
        item.status = status
      }

      return item
    })
  },

  setBookingComments: (state, payload) => {
    let key = Object.keys(payload.data)[0];
    state.comments = payload.data[key].comments
  },

  setLockedBooking: (state, payload) => {
    let key = Object.keys(payload.data)[0];
    if(key != 'booking') {
      state.locked_booking = payload.data.data.booking.locked_booking
    }
    else {
      state.locked_booking = payload.data[key].locked_booking
    }
  },


  resetConfirmationFills(state) {
    // state.confirmationFills = _.cloneDeep(state.confirmationFillsFresh)
    state.confirmationFills = {
      booker_name: '',
      reference: '',
      staff_name: '',
      email: [],
      fax: '',
      faxEnabled: false,
      sms: '',
      translator_user: '',
      agreed_related_languages: '',
      address: '',
      town: '',
      city: '',
      instructions: '',
      notification_types: [],
      created_via: 'DT-Admin web',
      video_provider: 'jitsi',
      message: "",
      message_files: [],
    }
    //
    // if (state.bookingForm.type ==='video'){
    //   state.confirmationFills.video_provider = 'jitsi'
    // }
  },

  setBookingField: (state, payload) => {
    state.bookingField = _.cloneDeep(state.bookingFieldFresh)
    Object.assign(state.bookingField, payload)
    
    if(state.bookingField.staff_name_enabled && state.bookingField.staff_name_required){
      state.bookingField.staff_name_class = 'label-medium red'
    }
    if(state.bookingField.staff_name_enabled && !state.bookingField.staff_name_required){
      state.bookingField.staff_name_class = 'label-medium'
    }
    if(state.bookingField.lma_enabled && !state.bookingField.lma_required){
      state.bookingField.lma_class = 'label-medium'
    }
    if(state.bookingField.lma_enabled && state.bookingField.lma_required){
      state.bookingField.lma_class = 'label-medium red'
    }

    if(state.bookingField.booker_name_enabled && state.bookingField.booker_name_required){
      state.bookingField.booker_name_class = 'label-medium red'
    }
    if(state.bookingField.booker_name_enabled && !state.bookingField.booker_name_required){
      state.bookingField.booker_name_class = 'label-medium'
    }
    if(state.bookingField.reference_enabled && state.bookingField.reference_required){
      state.bookingField.reference_class = 'label-medium red'
    }
    if(state.bookingField.reference_enabled && !state.bookingField.reference_required){
      state.bookingField.reference_class = 'label-medium'
    }
  },
  setUsersAll: (state, payload) => {
    state.usersAll = payload
  },

  /**
   * @param {object} state
   * @param {object} payload
   * @return {void}
   */
  setAvailableTranslators(state, payload) {
    state.availableTranslators = payload;
  },

  setEmailList(state,payload){
    state.emailList = payload
  },
  setBookingFeedbacks(state,payload){
    state.booking.feedbacks = payload
  },
  setBookingTraveltime(state,payload){
    state.traveltime = payload
  },

  setCurrentPending(state){
    let total = state.bookings.length
    let count = state.bookings.filter(o => o.status_id == 1).length

    let percentage = (count ==0 || total==0) ? 0 : (count/total) * 100
    state.currentPending = {
      count :count,
      percentage: Math.round(percentage)
    }
  },
  setCurrentAccepted(state){
    let total = state.bookings.length
    let count = state.bookings.filter(o => o.status_id == 2).length

    let percentage = (count ==0 || total==0) ? 0 : (count/total) * 100
    state.currentAccepted = {
      count :count,
      percentage: Math.round(percentage)
    }
  },
  setTotalCompleted(state){
    let total = state.bookings.length
    let count = state.bookings.filter(o => o.status_id == 4).length
    let percentage = (count ==0 || total==0) ? 0 : (count/total) * 100
    state.totalCompleted = {
      count :count,
      percentage: Math.round(percentage)
    }
  },
  setTotalCancelled(state){
    let total = state.bookings.length
    let count = state.bookings.filter(o => o.status_id == 5).length
    let percentage = (count ==0 || total==0) ? 0 : (count/total) * 100
    state.totalCancelled = {
      count :count,
      percentage: Math.round(percentage)
    }
  },
  setLateCancelled(state){
    let total = state.bookings.length
    let count = state.bookings.filter(o => o.status_id == 6).length
    let percentage = (count ==0 || total==0) ? 0 : (count/total) * 100
    state.lateCancelled = {
      count :count,
      percentage: Math.round(percentage)
    }
  },
  setNotCarriedOutTranslator(state){
    let total = state.bookings.length
    let count = state.bookings.filter(o => o.status_id == 9).length
    let percentage = (count ==0 || total==0) ? 0 : (count/total) * 100
    state.notCarriedOutTranslator = {
      count :count,
      percentage: Math.round(percentage)
    }
  },
  setNotCarriedOutCustomer(state){
    let total = state.bookings.length
    let count = state.bookings.filter(o => o.status_id == 10).length
    let percentage = (count ==0 || total==0) ? 0 : (count/total) * 100
    state.notCarriedOutCustomer = {
      count :count,
      percentage: Math.round(percentage)
    }
  },
  setSpecificTranslators(state,payload){
    state.bookingForm.specific_translators = payload
  },
  setExcludeTranslators(state,payload){
    state.bookingForm.exclude_translators = payload
  },

  setLateWithdrawal(state,payload){

    let group =  _(payload)
    .groupBy('booking_id')
    .map( function(i,v) {
      return {
        booking_id:v,
        attempts:i
      }
    }).value();
    let count = group.length

    state.lateWithdrawal = {
      count :count,
      percentage: count,
    }
  },


  setIsSpecificTranslatorReject(state,payload){
    let reject = [];
    let batches = payload.bookingBatches.item.data
    if(!isEmpty(batches)){
      reject = batches.map(o => {

        let rejects = o.entries.filter(i => i.rejected_at !== null)
        return rejects
      });

      state.isSpecificTranslatorReject = !isEmpty(reject);
    }
    state.isSpecificTranslatorReject = !isEmpty(reject);
    // console.log(state.booking.isSpecificTranslatorReject)
  },
  setTownList(state,payload){
    state.towns = payload
  },
  setStaffName: (state, payload) => {
    state.confirmationFills.staff_name =payload;
  },
  setAddress: (state, payload) => {
    state.confirmationFills.address =payload;
  },
  setCity: (state, payload) => {
    state.confirmationFills.city =payload;
  },
  setBookerName: (state, payload) => {
    state.confirmationFills.booker_name =payload;
  },
  setUserLockedBookings: (state, payload) => {
    state.userLockedBookings = payload;
  },
  removeUserFromLockedBooking: (state,payload) => {
    state.userLockedBookings = _.remove(state.userLockedBookings, (obj) => {
      return obj.user_id === payload.user_id && obj.booking_id != payload.booking_id;
    });
  },
  setReasons: (state, payload) => {
    state.reasons = payload;
  },

  /**
   * Set user inside booking list
   * @param {*} state
   * @param {*} payload
   */
  setUserInsideBooking(state, payload) {
    const user_id = JSON.parse(localStorage.getItem("app_user_data")).id
    //Filter user and removing the which is inside the booking
    let user = _.uniqBy(_.filter(payload, function(o) {
      return o.user_id != user_id
    }), 'user_id')
    state.usersInsideBooking = user
  },
  setLockedBookingUser(state, payload)
  {
    state.lockedBookingUser = payload
  },

  appendListData(state, payload) {
    state.list.data[payload.idx].has_replies = true;
    // console.log(state.list.data);
    // console.log(context.state.list.data[payload.idx])
  }
}
